import SideBar from "../project/SideBar";
import { useState, useEffect } from 'react';
import httpClientPy from '../../utils/httpClientPy';

const Training = () => {

    const [admin, setAdmin] = useState(false);
    const [loading, setLoading] = useState(true);
    const [userInfo, setUserInfo] = useState(null);

    
    useEffect(() => {
        getUser();
    }, []);
    
    const getUser = () => {
        httpClientPy.get(`/user`).then((response) => {
            let userList = response.data
            if (userList.is_enterprise) {
                userList.allow_digitise = userList.enteprise_is_digitiser
                userList.allow_civilsgpt = userList.enterprise_is_civilsgpt
            } else {
                userList.allow_digitise = userList.subscription_details ?  userList.subscription_details.is_digitise : false
                userList.allow_civilsgpt = userList.subscription_details ? userList.subscription_details.is_civilsgpt : false
            }
            setUserInfo(userList)
            setAdmin(userList.enterprise_admin)
            setLoading(false)
        })
    }
    


    return (
        <div className="d-flex">
            <div id="sidebarParent" className=' d-none d-md-block' style={{minWidth:'190px'}}>
                <SideBar page={'training'} loading={loading} userInfo={userInfo} admin={admin}/>

            </div> 
            <div  className="flex-fill px-3 pe-lg-0 py-4 row" style={{maxWidth:'100vw'}}>

                <div className="container" style={{maxWidth:'1300px'}}>
                    <h1 className="title-bold">Civils.ai Training Center</h1>

                    <div className="card mt-3 bg-white border curveborder text-dark shadow mb-5">
                        <div className="card-header fs-4 index_title">
                            Your resources
                        </div>

                        <div className="card-body">
                            <div className="row">
                            <div className="col">
                                <h5>🎓 Training hub</h5>
                                <div>We use Notion to store all the additional training resources, this page contains all the resources you need for each lesson.</div> 
                                <a className="btn btn-sm btn-outline-dark mt-2" target="_blank" href="https://civils-corp.notion.site/Civils-ai-Training-Resources-51cf224304f0475a97ee70199b3bc2c4?pvs=4">Open Notion Hub</a>
                                <h5 className="pt-4">🙋‍♂️ Need more help?</h5>
                                <div>Ask questions for support here</div>
                                <a className="btn btn-outline-dark btn-sm mt-2 " href="https://discord.gg/UFaKqhGNmb">Ask for support</a>
                            </div>
                            <div className="col d-none d-sm-block">
                                <h5>🛠️ No code tools</h5>
                                <div>We create and customise several No Code AI tools, here is a link to some of the tools we use:</div>
                                <a className="btn btn-outline-dark btn-sm mt-2 " target="_blank" href="https://chatgpt.com/g/g-BAwyt0AZn-construction-contract-negotiator">Construction Contract AI</a>
                                <a className="btn btn-outline-dark btn-sm mt-2 " target="_blank" href="https://chatgpt.com/g/g-vrGUIQG9n-construction-inspection-gpt">Site Inspection Report AI</a>
                                <a className="btn btn-outline-dark btn-sm mt-2 " target="_blank" href="https://app.civils.ai/">Project report and CAD AI</a>

                            </div>
                            <div className="col d-none d-sm-block">
                                <h5>👩🏻‍💻 Course code snippets</h5>
                                <div>Github repositories for all the Python we write in the course:</div>
                                <a className="btn btn-outline-dark btn-sm mt-2 " target="_blank" href="https://github.com/slukiceng/construction-analysis-ai">Construction Contract AI</a>
                                <a className="btn btn-outline-dark btn-sm mt-2 " target="_blank" href="https://github.com/slukiceng/site-inspection-ai">Site Inspection Report AI</a>
                                <a className="btn btn-outline-dark btn-sm mt-2 " target="_blank" href="https://github.com/slukiceng/blueprints-analysis-app.git">CAD Estimation AI</a>
                                <a className="btn btn-outline-dark btn-sm mt-2 " target="_blank" href="https://github.com/slukiceng/building-redesign-ai">Building Redesign AI</a>

                            </div>
                            <div className="col d-none d-sm-block">
                                <h5>🧠 Build No-Code AI Workflows</h5>
                                <div className="pb-2">Get access to our AI Workflow Builder</div>
                                <div>
                                    <video className="mx-auto shadow rounded" style={{ borderRadius: '10px' }} loading="lazy" width="100%" autoPlay playsInline muted loop>
                                        <source src="https://d122f4822rob2u.cloudfront.net/no-code-ai-workflow.mp4" type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                                <div className="pt-2">👇 Book a meeting with us to get set up</div>
                                <a className="btn btn-outline-dark btn-sm mt-2 " target="_blank" href="https://calendar.app.google/bCKSrP52dNUzutcW6">Get access</a>
                            </div>
                            </div>
                        </div>
                        </div>




                        <div className="card bg-white border curveborder text-dark shadow my-5 reveal2">
                            <div className="card-header fs-4 index_title">
                            Module 1 - Course Introduction
                            </div>
                            <div className="card-body">
                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/L3KNYvKPzWY?si=OMcT0DKA83uNQshX" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

                                </div>
                                <div className="col-12 col-sm-6">
                                <div className="card-text pt-1 fs-6">
                                    <div>We kick of the course by explaining exactly how the course works</div>
                                    <div className="py-3"><small><svg xmlns="http://www.w3.org/2000/svg" height="1rem" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-clock"><circle cx="12" cy="12" r="10"/><polyline points="12 6 12 12 16 14"/></svg> Lesson duration: 10 mins</small> <small><svg xmlns="http://www.w3.org/2000/svg" height="1rem" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-notebook-pen"><path d="M13.4 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-7.4"/><path d="M2 6h4"/><path d="M2 10h4"/><path d="M2 14h4"/><path d="M2 18h4"/><path d="M18.4 2.6a2.17 2.17 0 0 1 3 3L16 11l-4 1 1-4Z"/></svg> Lesson type: Theory</small></div>
                                    <div>Things you will learn:</div>
                                    <small>
                                    <ol>
                                        <li>Structure of the course with theory & practical</li>
                                        <li>Introduction to your instructor</li>
                                        <li>Course resources</li>
                                    </ol>
                                    </small>
                                    <div>Cheat sheet for this lesson from the training hub is below</div>
                                    <a className="btn btn-outline-dark btn-sm mt-2 " target="_blank" href="https://civils-corp.notion.site/Lesson-1-0beed6738ba24942ad17da8843c08a5a">Lesson 1 cheat sheet</a>
                                </div>

                                </div>

                            </div>
                            </div>
                        </div>


                        <div className="card bg-white border curveborder text-dark shadow mb-5 reveal2">
                            <div className="card-header fs-4 index_title">
                            Module 2 - History and foundations of AI
                            </div>
                            <div className="card-body">
                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/l_SWPebVpWM?si=djiwOLfmT_UkdXoX" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                                <div className="col-12 col-sm-6">
                                <div className="card-text pt-1 fs-6">
                                    
                                    <div>Get a fundamental understanding of foundational AI technologies and how AI applications work</div>
                                    <div className="py-3"><small><svg xmlns="http://www.w3.org/2000/svg" height="1rem" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-clock"><circle cx="12" cy="12" r="10"/><polyline points="12 6 12 12 16 14"/></svg> Lesson duration: 30 mins</small> <small><svg xmlns="http://www.w3.org/2000/svg" height="1rem" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-notebook-pen"><path d="M13.4 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-7.4"/><path d="M2 6h4"/><path d="M2 10h4"/><path d="M2 14h4"/><path d="M2 18h4"/><path d="M18.4 2.6a2.17 2.17 0 0 1 3 3L16 11l-4 1 1-4Z"/></svg> Lesson type: Theory</small></div>
                                    <div>Things you will learn:</div>
                                    <small>
                                    <ol>
                                    <li>The history of AI going back to the 1800's</li>
                                    <li>Demystifying definitions and terminology</li>
                                    <li>An overview of foundational AI technologies</li>
                                    <li>How AI models are training and deployed</li>
                                    </ol>
                                    </small>
                                    <div>Cheat sheet for this lesson from the training hub is below</div>
                                    <a className="btn btn-outline-dark btn-sm mt-2 " target="_blank" href="https://civils-corp.notion.site/Lesson-2-1698da9ee3f24c338891a84b42830175">Lesson 2 cheat sheet</a>

                                </div>

                                </div>

                            </div>
                            </div>
                        </div>


                        <div className="card bg-white border curveborder text-dark shadow mb-5 reveal2">
                            <div className="card-header fs-4 index_title">
                            Module 3 - Implementing AI on Construction projects with No-Code solutions
                            </div>
                            <div className="card-body">
                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/omOjDTXuNzo?si=IwX6cZvwx69T_ABU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>              
                                </div>
                                <div className="col-12 col-sm-6">

                                    <div className="card-text pt-1 fs-6">
                                    
                                    <div>We show you how to use to AI (and customise it) to boost your productivity on construction projects with no coding required.</div>
                                    <div className="py-3"><small><svg xmlns="http://www.w3.org/2000/svg" height="1rem" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-clock"><circle cx="12" cy="12" r="10"/><polyline points="12 6 12 12 16 14"/></svg> Lesson duration: 45 mins</small> <small><svg xmlns="http://www.w3.org/2000/svg" height="1rem" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-notebook-pen"><path d="M13.4 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-7.4"/><path d="M2 6h4"/><path d="M2 10h4"/><path d="M2 14h4"/><path d="M2 18h4"/><path d="M18.4 2.6a2.17 2.17 0 0 1 3 3L16 11l-4 1 1-4Z"/></svg> Lesson type: Practical</small></div>
                                    <div>Things you will learn:</div>
                                    <small>
                                    <ol>
                                        <li>What are AI Agents?</li>
                                        <li>How can we use AI Agents in construction?</li>
                                        <li>Implement our own AI Agents for construction tasks:</li>
                                        <ol>
                                        <li>Create an AI Agent for automatically analysing construction contracts, writing a table of contract risks & drafting a renegotiation email.</li>
                                        <li>Create an AI Agent for analysing site photographs and writing a first draft of a site inspection report.</li>
                                        <li>Create an AI Agent which understands all my building codes, design documents and CAD.</li>
                                        </ol>
                                    </ol>
                                    </small>
                                    <div>Cheat sheet for this lesson from the training hub is below</div>
                                    <a className="btn btn-outline-dark btn-sm mt-2 " target="_blank" href="https://civils-corp.notion.site/Lesson-3-24853f24000b48ffb0f3303dd36352f6">Lesson 3 cheat sheet</a>
                                    </div>

                                </div>

                            </div>
                            </div>
                        </div>

                        <div className="technical">
                            <div className="card bg-white border curveborder text-dark shadow mb-5 reveal2">
                            <div className="card-header fs-4 index_title">
                                Module 4 - Implementing AI on Construction projects with Python
                            </div>
                            <div className="card-body">

                                <div className="row">
                                <div className="col-12 col-sm-6">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/fgtBN3DklA0?si=SwmIZb0Ij8TnjUt4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="card-text pt-1 fs-6">
                                    <div className="badge py-2 bg-danger text-start">No prior coding experience required!</div>
                                    <div className="pt-2">We guide you in how to start basic Python scripting and how to interact with Large Language Models with just a few lines of code.</div>
                                    <div className="py-3"><small><svg xmlns="http://www.w3.org/2000/svg" height="1rem" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-clock"><circle cx="12" cy="12" r="10"/><polyline points="12 6 12 12 16 14"/></svg> Lesson duration: 60 mins</small> <small><svg xmlns="http://www.w3.org/2000/svg" height="1rem" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-notebook-pen"><path d="M13.4 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-7.4"/><path d="M2 6h4"/><path d="M2 10h4"/><path d="M2 14h4"/><path d="M2 18h4"/><path d="M18.4 2.6a2.17 2.17 0 0 1 3 3L16 11l-4 1 1-4Z"/></svg> Lesson type: Practical</small></div>
                                    <div>Things you will learn:</div>
                                    <small>
                                    <ol>
                                        <li>Get started using Python (beginner friendly crash course)</li>
                                        <ol>
                                        <li>Scripts and algorithms</li>
                                        <li>Data structures</li>
                                        <li>Using open-source libraries</li>
                                        </ol>
                                        <li>API's and AI model inference</li>
                                        <ol>
                                        <li>Fetching real time information</li>
                                        <li>Interacting with Large Language Models</li>
                                        </ol>
                                        <li>Coding your own Autonomous AI agents</li>
                                        <ol>
                                        <li>Build your own AI for estimating quantities from PDF CAD Drawings</li>
                                        </ol>
                                    </ol>
                                    </small>
                                    <div>Cheat sheet for this lesson from the training hub is below</div>
                                    <a className="btn btn-outline-dark btn-sm mt-2 " target="_blank" href="https://civils-corp.notion.site/Lesson-4-fa6c6546239e45e080391a08594ad7dc">Lesson 4 cheat sheet</a>
                                    </div>

                                </div>

                                </div>

                            </div>
                            </div>


                            <div className="card bg-white border curveborder text-dark shadow mb-5 reveal2">
                            <div className="card-header fs-4 index_title">
                                Module 5 - Publishing your AI applications
                            </div>
                            <div className="card-body">

                                <div className="row">
                                <div className="col-12 col-sm-6">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/SKOKICCbwps?si=TjlKusTXdshlEWx0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                                <div className="col-12 col-sm-6">

                                    <div className="card-text pt-1 fs-6">
                                    
                                    <div>Publish your AI application so that others in your team or company can use the solution you've built.</div>
                                    <div className="py-3"><small><svg xmlns="http://www.w3.org/2000/svg" height="1rem" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-clock"><circle cx="12" cy="12" r="10"/><polyline points="12 6 12 12 16 14"/></svg> Lesson duration: 50 mins</small> <small><svg xmlns="http://www.w3.org/2000/svg" height="1rem" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-notebook-pen"><path d="M13.4 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-7.4"/><path d="M2 6h4"/><path d="M2 10h4"/><path d="M2 14h4"/><path d="M2 18h4"/><path d="M18.4 2.6a2.17 2.17 0 0 1 3 3L16 11l-4 1 1-4Z"/></svg> Lesson type: Practical</small></div>
                                    <div>Things you will learn:</div>
                                    <small>
                                    <ol>
                                        <li>Introduction to hosting web applications</li>
                                        <ol>
                                        <li>Developing AI apps with Streamlit</li>
                                        <li>Storing code on Github</li>
                                        </ol>
                                        <li>Creating our AI web application</li>
                                        <ol>
                                        <li>Publishing our AI for estimating quantities from PDF CAD Drawings</li>
                                        </ol>
                                        <li>Controlling access to our application</li>
                                    </ol>
                                    </small>
                                    <div>Cheat sheet for this lesson from the training hub is below</div>
                                    <a className="btn btn-outline-dark btn-sm mt-2 " target="_blank" href="https://civils-corp.notion.site/Lesson-5-142980612f5946a88af4d1363e50e93f">Lesson 5 cheat sheet</a>
                                    </div>


                                </div>

                                </div>

                            </div>
                            </div>


                            <div className="card bg-white border curveborder text-dark shadow mb-5 reveal2">
                            <div className="card-header fs-4 index_title">
                                Module 6 - Fine-tuning AI for Construction
                            </div>
                            <div className="card-body">

                                <div className="row">
                                <div className="col-12 col-sm-6">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/aWfG1k49r7Q?si=v9oC-iixJGElW6nO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                                <div className="col-12 col-sm-6">


                                    <div className="card-text pt-1 fs-6">
                                    
                                    <div>In this final practical session we will bring together everything we've learnt and build 4 complete apps for different construction use cases.</div>
                                    <div className="py-3"><small><svg xmlns="http://www.w3.org/2000/svg" height="1rem" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-clock"><circle cx="12" cy="12" r="10"/><polyline points="12 6 12 12 16 14"/></svg> Lesson duration: 60 mins</small> <small><svg xmlns="http://www.w3.org/2000/svg" height="1rem" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-notebook-pen"><path d="M13.4 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-7.4"/><path d="M2 6h4"/><path d="M2 10h4"/><path d="M2 14h4"/><path d="M2 18h4"/><path d="M18.4 2.6a2.17 2.17 0 0 1 3 3L16 11l-4 1 1-4Z"/></svg> Lesson type: Practical</small></div>
                                    <div>Things you will learn:</div>
                                    <small>
                                    <ol>
                                        <li><b>AI for CAD:</b> We will finish off our AI for estimating quantities from PDF CAD Drawings generating a downloadable Excel file.</li>
                                        <li><b>AI for Civil Engineering:</b> AI for analysing site photographs and writing a draft inspection report as a downloadable Word file.</li>
                                        <li><b>AI for Contractors:</b> AI for analysing construction contracts and creating a table of the key risks including pages numbers along with a renegotiation email.</li>
                                        <li><b>AI for Architects:</b> AI for restyling building interiors and exteriors using photographs you've uploaded and a prompt.</li>

                                    </ol>
                                    </small>
                                    <div>Cheat sheet for this lesson from the training hub is below</div>
                                    <a className="btn btn-outline-dark btn-sm mt-2 " target="_blank" href="https://civils-corp.notion.site/Lesson-6-2cb8fd1bb9b3459c884a04adc72711e4">Lesson 6 cheat sheet</a>
                                    </div>

                                </div>

                                </div>

                            </div>
                            </div>
                        </div>

                        <div className="card bg-white border curveborder text-dark shadow mb-5">
                            <div className="card-header fs-4 index_title">
                            Module <span className="technical">7</span><span className="non-technical">4</span> - AI Safety and Regulations in 2024
                            </div>
                            <div className="card-body">

                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/MauhBhxyiwY?si=R5C9mrACPBnBwy92" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                                <div className="col-12 col-sm-6">

                                    <div className="card-text pt-1 fs-6">
                                    <div>We explain the AI and Privacy regulations you need to know about in 2024 if you are developing or using AI applications</div>
                                    <div className="py-3"><small><svg xmlns="http://www.w3.org/2000/svg" height="1rem" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-clock"><circle cx="12" cy="12" r="10"/><polyline points="12 6 12 12 16 14"/></svg> Lesson duration: 20 mins</small> <small><svg xmlns="http://www.w3.org/2000/svg" height="1rem" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-notebook-pen"><path d="M13.4 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-7.4"/><path d="M2 6h4"/><path d="M2 10h4"/><path d="M2 14h4"/><path d="M2 18h4"/><path d="M18.4 2.6a2.17 2.17 0 0 1 3 3L16 11l-4 1 1-4Z"/></svg> Lesson type: Theory</small></div>
                                    <div>Things you will learn:</div>
                                    <small>
                                    <ol>
                                        <li>Global AI and privacy regulations which are in effect today from both the developer and user perspective</li>
                                        <li>Regulations on the horizon</li>
                                        <li>How to make sure you stay on the right side of the regulations</li>
                                    </ol>
                                    </small>
                                    <div>Cheat sheet for this lesson from the training hub is below</div>
                                        <a className="btn btn-outline-dark btn-sm mt-2 " target="_blank" href="https://civils-corp.notion.site/Lesson-7-2baf7485ccb946f992e7a93b79542931">Lesson 7 cheat sheet</a>
                                </div>

                                </div>

                            </div>

                            </div>
                        </div>


                        <div class="row">
                            <div class="col-12 col-md-6 reveal1">
                                <div>
                                    <h3 class="mb-1 index_title py-4 text-dark text-center text-sm-start">Your final project</h3>
                                    <p>In order for us to issue your final course completion certification we need you to have completed your final project.</p>

                                    <p>This final project is completely open to you but should involve elements you've learnt from the course, here are a couple of examples of final projects for inspiration:</p>
                                    <ul>
                                        <li>A Streamlit application for creating comments on a CAD drawing</li>
                                        <li>A Custom GPT for searching a database of historical projects and finding similar projects</li>  
                                        <li>Adding additional functionality onto one of the examples we already built in the course, perhaps you'd like to enhance the results of the building design AI by adding another step to the AI pipeline for better segmentation.</li>
                                    </ul>

                                    <p>In your submission we ask the following two things:</p>
                                    <ul>
                                        <li>You create a 1 minute public YouTube video explaining your final project. In your video answer the following questions: What problem does it solve for you? How did you build it?</li>
                                        <li>Github repository (optional). If you wish you can also provide the Github repository if you'd like for your final project to be open source and for others to potentially collaborate on the project with you.</li>
                                    </ul>

                                    <p>Civils.ai was born out of a final project like this so I hope that you capitalise on the opportunity to try and build something useful for yourself using the knowledge available on this course!</p>
                                </div>
                                
                            </div>
                            <div class="col-12 col-md-6">
                                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfnpv1iaGK-ZHR-BlPA4dYYIuvNKXSNty_sI5hBnQEhYkfpsA/viewform?embedded=true" width="640" height="689" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>

                            </div>
                            </div>
                        </div>

                        <div className="text-center my-5 py-5">
                            If you have any queries or run into any issues with the training materials or software please contact us on <b>hello@civils.ai</b>
                        </div>

                </div>
            </div>
    )

}

export default Training;