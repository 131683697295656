import { Card } from "react-bootstrap";


const NodeCanvasSelector = (props) => {

    return (
        <div style={{
            position: 'absolute',
            left: props.x,
            top: props.y,
            backgroundColor: 'white',
            border: '1px solid #ccc',
            borderRadius: '4px',
            padding: '10px',
            zIndex: 1000,
            boxShadow: '0 0 5px #ccc',
            width: '200px'
        }}>
            <h6><b>Add a node</b></h6>
            <Card className={`nodes-card-preview ${props.sourceNode !== 'selector' ? 'd-none': ''}`} 
                    style={{ width: '100%' }} 
                    onClick={() => props.setSelectedNode('search')}>

                <div className="px-2 py-2">
                    <div className="d-flex justify-content-between align-items-center">
                        <span>Search</span>
                    </div>
                </div>
            </Card>
            <Card className={`nodes-card-preview ${props.sourceNode !== 'search' ? 'd-none': ''}`} 
                    style={{ width: '100%' }} 
                    onClick={() => props.setSelectedNode('task')}>

                <div className="px-2 py-2">
                    <div className="d-flex justify-content-between align-items-center">
                        <span>Task Instruction</span>
                    </div>
                </div>
            </Card>
        </div>
    )

}

export default NodeCanvasSelector;