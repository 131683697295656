import { useEffect, useState } from "react";
import { Modal, ModalHeader, Nav, NavItem, NavLink, Spinner } from "reactstrap";
import FileSelectorTable from "./FileSelectorTable";
import FileSelectorFilter from "./FileSelectorFilter";


const FileSelectorModal = (props) => {

    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(props.isDigitsationWf ? 3: 0);
    const [displaySearch, setDisplaySearch] = useState(false);
    const [searchFilter, setSearchFilter] = useState({file_names: []});

    const toggleTab = (tab) => {
        if (props.isDigitsationWf) { return }
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }

    function filterRecords(selections) {
        if (selections.length > 0) {
            selections.forEach((item) => {
                setSearchFilter({...searchFilter, file_names: [...searchFilter.file_names, item.value]})
            })
        } else {
            setSearchFilter({"file_names": []})
        }
    }
    
    return (
        <Modal isOpen={props.toggleFileSelector} toggle={() => props.setToggleFileSelector(!props.toggleFileSelector)} fullscreen>
            <ModalHeader toggle={() => props.setToggleFileSelector(!props.toggleFileSelector)}>Select which document(s) you'd like to use in this workflow</ModalHeader>
            {loading ? (
                <div className='d-flex justify-content-center align-items-center' style={{ height: '50vh' }}>
                    <Spinner color='primary' />
                </div>
            ) : (
                <>
                    <div className="ms-2">
                        <Nav tabs className="custom-nav-tabs" style={{ paddingBottom: '0px' }}>
                            <NavItem className="custom-nav-item">
                                <NavLink
                                    className={`${activeTab === 0 ? 'custom-nav-link-active' : 'custom-nav-link'}`}
                                    onClick={() => toggleTab(0)}
                                >
                                    All <span className='badge custom-nav-link-count-box ms-2'>{props.extraDetails.all_data}</span>
                                </NavLink>
                            </NavItem>
                            <NavItem className="custom-nav-item">
                                <NavLink
                                    className={`${activeTab === 1 ? 'custom-nav-link-active' : 'custom-nav-link '}`}
                                    onClick={() => toggleTab(1)}
                                >
                                    Specs <span className='badge custom-nav-link-count-box ms-2'>{props.extraDetails.all_specs_data}</span>
                                </NavLink>
                            </NavItem>
                            <NavItem className="custom-nav-item">
                                <NavLink
                                    className={`${activeTab === 2 ? 'custom-nav-link-active' : 'custom-nav-link '}`}
                                    onClick={() => toggleTab(2)}
                                >
                                    Contracts <span className='badge custom-nav-link-count-box ms-2'>{props.extraDetails.all_contracts_data}</span>
                                </NavLink>
                            </NavItem>
                            <NavItem className="custom-nav-item">
                                <NavLink
                                    className={`${activeTab === 3 ? 'custom-nav-link-active' : 'custom-nav-link '}`}
                                    onClick={() => toggleTab(3)}
                                >
                                    Site Investigations <span className='badge custom-nav-link-count-box ms-2'>{props.extraDetails.all_site_inv_data}</span>
                                </NavLink>
                            </NavItem>
                            <NavItem className="custom-nav-item">
                                <NavLink
                                    className={`${activeTab === 4 ? 'custom-nav-link-active' : 'custom-nav-link '}`}
                                    onClick={() => toggleTab(4)}
                                >
                                    Drawings <span className='badge custom-nav-link-count-box ms-2'>{props.extraDetails.all_drawings_data}</span>
                                </NavLink>
                            </NavItem>
                            <NavItem className="custom-nav-item">
                                <NavLink
                                    className={`${activeTab === 5 ? 'custom-nav-link-active' : 'custom-nav-link '}`}
                                    onClick={() => toggleTab(5)}
                                >
                                    Health & Safety<span className='badge custom-nav-link-count-box ms-2'>{props.extraDetails.all_risk_regs_data}</span>
                                </NavLink>
                            </NavItem>
                            <NavItem className="custom-nav-item">
                                <NavLink
                                    className={`${activeTab === 7 ? 'custom-nav-link-active' : 'custom-nav-link '}`}
                                    onClick={() => toggleTab(7)}
                                >
                                    Plans & Methods <span className='badge custom-nav-link-count-box ms-2'>{props.extraDetails.all_plans_data}</span>
                                </NavLink>
                            </NavItem>
                            <NavItem className="custom-nav-item">
                                <NavLink
                                    className={`${activeTab === 6 ? 'custom-nav-link-active' : 'custom-nav-link '}`}
                                    onClick={() => toggleTab(6)}
                                >
                                    Other <span className='badge custom-nav-link-count-box ms-2'>{props.extraDetails.all_others_data}</span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                    <div className="bg-white border rounded shadow-sm d-flex flex-column flex-grow-1" style={{overflowY:'auto'}}>
                        <FileSelectorFilter activeTab={activeTab}
                                            displaySearch={displaySearch}
                                            setDisplaySearch={setDisplaySearch}
                                            filterRecords={filterRecords}
                                            project = {props.project}
                        />
                        
                        <FileSelectorTable  docType = {activeTab} 
                                            project = {props.project}
                                            searchFilter = {searchFilter} 
                                            filesSelected = {props.filesSelected}
                                            setFileSelected = {props.setFilesSelected}
                        />

                        <div className="d-flex justify-content-center align-items-center mb-3">
                            <button className="btn btn-primary mt-2" 
                                    onClick={() => props.setToggleFileSelector(!props.toggleFileSelector)}>
                                        
                                {props.filesSelected.length > 0 ? 'Confirm' + ` (${props.filesSelected.length})` : 'Close'}
                            </button>
                        </div>

                    </div>
                </>
            )}
        </Modal>
    );
}

export default FileSelectorModal;
