import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faStream } from '@fortawesome/free-solid-svg-icons';
import Canvas from './Canvas';
import { toast, ToastContainer } from 'react-toastify';
import WorkflowNavbar from '../navbar/WorkflowNavbar';
import NodeManager from '../sidebar/NodeManager';
import OutputLayout from '../outputs/OutputLayout';
import { Spinner } from 'reactstrap';
import UploadsList from '../../project/AiAssitantComponents/UploadsList';
import CanvasProvider from './Canvas';

const WorkFlow = (props) => {

    const [show, setShow] = useState(false);
    const [selectedNode, setSelectedNode] = useState(null);
    const [workflow, setWorkflow] = useState({name: 'Untitled Workflow', description: '', nodes: [], edges: [], is_digitsation: false});
    const [enableRun, setEnableRun] = useState(false);
    const [nodesError, setNodesError] = useState([]);
    const [loading, setLoading] = useState(false);
    const [globalFileToUpload, setGlobalFileToUpload] = useState([]);
    const [runTriggered, setRunTriggered] = useState(false);

    const updateSelectedNode = (name) => {
        if (name === 'search') {
            setSelectedNode('search');
        } else if (name === 'task') {
            setSelectedNode('task');
        } else if (name === 'output-json') {
            setSelectedNode('output-json');
        } else if (name === 'output-excel') {
            setSelectedNode('output-excel');
        } else if (name === 'output-docx') {
            setSelectedNode('output-docx');
        } else {
            setSelectedNode(null);
        }
    }

    useEffect(() => {

        if (props.digitiseFlow !== null) {
            // upadte the workflow is_digitsation key with the value from the props digitiseFlow
            setWorkflow({...workflow, is_digitsation: props.digitiseFlow, name: props.workflowName});
        }

        props.setDigitiseFlow(null);
        props.setWorkflowName(null);
    }, [props.digitiseFlow, props.workflowName]);

    return (
        <>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                    <Spinner color="primary" />
                </div>
            ) : (
                <>
                    <ToastContainer />
                    <WorkflowNavbar selectedBtn={props.selectedBtn}
                                    setSelectedBtn={props.setSelectedBtn}
                                    action={props.action}
                                    setAction={props.setAction}
                                    defaultAction={props.defaultAction} 
                                    workflow={workflow}
                                    setWorkflow={(data) => setWorkflow(data)}
                                    superAdmin = {props.superAdmin}
                                    nodesError={nodesError}
                                    setNodesError={(data) => setNodesError(data)}
                                    project = {props.project}
                                    workFlowId = {props.workFlowId}
                                    workFlowRunId = {props.workFlowRunId}
                                    enableRun={enableRun}
                                    globalFileToUpload={globalFileToUpload}
                                    setGlobalFileToUpload={(data) => setGlobalFileToUpload(data)}
                                    setRunTriggered={(status) => setRunTriggered(status)}
                    />

                    <div className='d-flex'>
                        {!show && props.selectedBtn === 'editor' &&
                            <button
                                className="floating-button btn btn-primary"
                                onClick={() => setShow(!show)}
                                style={{
                                    right: '98vw',
                                }}
                            >
                                <FontAwesomeIcon icon={show ? faArrowLeft : faArrowRight} size="lg" />
                            </button>
                        }

                        {props.selectedBtn === 'editor' || props.selectedBtn == 'run' ? (
                            <>
                                <NodeManager show={show}
                                            setShow={setShow}
                                            updateSelectedNode={updateSelectedNode} />
                                    
                                <CanvasProvider show={show} 
                                        selectedNode={selectedNode}
                                        setSelectedNode={(data) => setSelectedNode(data)}
                                        project = {props.project}
                                        extraDetails = {props.extraDetails}
                                        workflow = {workflow}
                                        setWorkflow={(data) => setWorkflow(data)}
                                        nodesError={nodesError}
                                        setNodesError={(data) => setNodesError(data)}
                                        workFlowId = {props.workFlowId}
                                        workFlowRunId = {props.workFlowRunId}
                                        setEnableRun={(status) => setEnableRun(status)}
                                        action={props.action}
                                        setSelectedBtn={props.setSelectedBtn}
                                        globalFileToUpload={globalFileToUpload}
                                        setGlobalFileToUpload={(data) => setGlobalFileToUpload(data)}
                                        runTriggered={runTriggered}
                                />
                            </>
                        ) : (
                            <>                              
                                {!workflow.is_digitsation ? (
                                    <OutputLayout workFlowId = {props.workFlowId}
                                                  workFlowRunId = {props.workFlowRunId}
                                                  project = {props.project}
                                    />
                                ) : (
                                    <UploadsList project={props.project} 
                                                 pageView={1}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </>
            )}
        </>
    );
}

export default WorkFlow;