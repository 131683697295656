import Select, { components } from 'react-select'

const FilterSelector = (props) => {

    const handleSelect = (selected) => {
        props.setFilters(selected)
    }

    return (
        <>
            {props.selections.length > 0 && 
                <Select
                    className="basic-multi-select d-inline-block filter-record-select-sm text-center me-2"
                    classNamePrefix="select"
                    isSearchable={true}
                    name="filter"
                    isMulti={true}
                    placeholder={props.cat ? "Filter Categories" : "Filter Workflow Names"}
                    components={{ ValueContainer }}
                    closeMenuOnSelect={false}
                    options={props.selections}
                    onChange={handleSelect}
                    autoFocus={true}
                    value={props.filters}
                />
            }
        </>
    )

}

export default FilterSelector;

const ValueContainer = ({ children, ...props }) => {
    if (children[0] !== null) {
      const length = children[0].length
      let tmpChildren = [...children];
      if(length >= 2){
        tmpChildren[0] = `${length} options selected`
      }
  
      return (
        <components.ValueContainer {...props}>{tmpChildren}</components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>{children}</components.ValueContainer>
      );
    }
};