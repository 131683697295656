import React, { useState, useRef, useEffect } from "react";
import { FeatureGroup, useMap} from "react-leaflet";
import L from "leaflet";
import { EditControl } from "react-leaflet-draw";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import httpClientPy from "../../../utils/httpClientPy";
import html2canvas from 'html2canvas';
import { useNavigate } from "react-router-dom";

const PolygonBhExtract = (props) => {
    // variable to store the mapLayers
    const [mapLayers, setMapLayers] = useState([]);
    // variable to store the state of polygon is completed
    const [polygonCompleted, setPolygonCompleted] = useState(false);
    // variable to store if polygon is reset
    const [polygonReset, setPolygonReset] = useState(false);

    // this is the reference to the polygon drawing tool
    const polyRef = useRef();
    const mapholder = useMap();
    let polygon = useRef();

    const navigate = useNavigate();

    // this function is ran when the page is loaded and the edit control are loaded
    const _onMounted = (e) => {
        document.getElementsByClassName('leaflet-control-container')[0].style.visibility = 'hidden';
        polyRef.current = e;
    };

    // this useEffect checks if the polyref is null or not
    useEffect(() => {
        if (!polyRef.current) {
            return;
        }
        // if not null then enable the polygon drawing tool with custom options and tooltip
        polygon.current = new L.Draw.Polygon(mapholder, polyRef.current.options.polygon);
        polygon.current.options.shapeOptions.color = "purple";
        polygon.current.options.allowIntersection = false;
        polygon.current.enable();
        L.drawLocal.draw.handlers.polygon.tooltip.start = "Click to start drawing area"
        L.drawLocal.draw.handlers.polygon.tooltip.cont = "Click to continue drawing area"
        L.drawLocal.draw.handlers.polygon.tooltip.end = "Click first point to close this area, press backspace to delete last vertex"
        document.getElementsByClassName("leaflet-draw-tooltip")[0].style.backgroundColor = "purple";
        return () => {
            polygon.current.disable();
        }
    }, [polyRef]);

    useEffect(() => {

        // this function is ran when the user press backspace to delete the last vertex
        const handleBackSpace = (event) => {
            if (event.keyCode === 8) {
                // if backspace is pressed, delete the last vertex using the polygon current
                polygon.current.deleteLastVertex();
            }
        };

        window.addEventListener('keydown', handleBackSpace);

        // Add event listener for keydown event
        const handleKeyEscDown = (event) => {
        if (event.keyCode === 27) {
            // If ESC key is pressed, disable drawing tool
            polygon.current.disable();
        }
        };
        window.addEventListener('keydown', handleKeyEscDown);

        // Add event listener for keyup event
        const handleKeyEscUp = (event) => {
            var layerContainer = polyRef.current.options.edit.featureGroup,
            layers = layerContainer._layers;
            if (Object.keys(layers).length === 0 && event.keyCode === 27) {
                // If ESC key is released, enable drawing tool
                polygon.current.enable();
                document.getElementsByClassName("leaflet-draw-tooltip")[0].style.backgroundColor = "purple";
            }
        };
        window.addEventListener('keyup', handleKeyEscUp);

        return () => {
            window.removeEventListener('keydown', handleBackSpace);
            window.removeEventListener('keydown', handleKeyEscDown);
            window.removeEventListener('keyup', handleKeyEscUp);
        };
    }, []);
    
    // this function is ran when the polygon is created or finished
    const _onCreate = (e) => {
        // get the layer type and layer
        const { layerType, layer } = e;
        
        // check if the layer type is polygon
        if (layerType === "polygon") {
            // get the layer id
            const { _leaflet_id } = layer;

            // add the layer to the mapLayers
            setMapLayers((layers) => [
                ...layers,
                { id: _leaflet_id, latlngs: layer.getLatLngs() },
            ]);
        }

        // get the area of the drawn polygon in m2
        let area = L.GeometryUtil.geodesicArea(layer.getLatLngs()[0]);
        // convert the area to 2 decimal places
        area = Math.round(area * 100) / 100
        
        // check if the area is less than 500000 m2
        if (area > 500000 && props.domain !== "costain.com" && !props.addProject && !props.updateProjectLocation) {
            props.updatePolygonState({current_step: 6, current_text: "Sorry, the area you've drawn (" + area + " m2) is too large. Please redraw less than 500000m2.", current_text_add: "", reset: true})
        } else {
            props.updatePolygonState({current_step: 2, current_text: "buttons", current_text_add: "", reset: false})
        }
        setPolygonCompleted(true)
        setPolygonReset(false)
    };

    useEffect(() => {
        // check if the current polyState reset is true
        if (props.polygonState.reset) {
            // if true then delete all the layers
            var layerContainer = polyRef.current.options.edit.featureGroup,
            layers = layerContainer._layers,
            layer_ids = Object.keys(layers),
            layer;

            layer_ids.forEach(id => {
                layer = layers[id]
                layerContainer.removeLayer(layer);
            })
            // set the mapLayers to empty
            setMapLayers([])
            // set the polygonCompleted to false
            setPolygonCompleted(false)
            
            // if the current step is 5 then it means the polygon was valid but there were no public data in the polygon, so trigger reset and request new polygon
            if (props.polygonState.current_step === 5) {
                props.updatePolygonState({current_step: 1, current_text: "There is no public data in the drawn area, please draw a new area", current_text_add: "", reset: false})
            } else if (props.polygonState.current_step !== 6) {
                // if user choose to reset polygon then trigger reset and create new canvas
                let text = props.addProject || props.updateProjectLocation ? "Draw a project area" : "Draw a polygon on the map to extract borehole data."
                props.updatePolygonState({current_step: 1, current_text: text, current_text_add: "", reset: false})
            } else {
                // if the current step is 6 then it means the polygon was too large, so trigger reset and request new polygon
                props.updatePolygonState({current_step: 6, current_text: props.polygonState.current_text, current_text_add: props.polygonState.current_text_add, reset: false})
            }
            // set the polygonReset to true
            setPolygonReset(true)
        }
        if (polygonReset) {
            // if reset is true then create new drawing tool with custom tooltip
            polygon.current.enable()
            document.getElementsByClassName("leaflet-draw-tooltip")[0].style.backgroundColor = "purple";
        }
        // if the current step is 3 means user confirmed the polygon and want to extract the data
        if (props.polygonState.current_step === 3) {
            // create empty array to store the coordinates
            var coords = [];

            if(props.projectType !== 0 && (props.addProject || props.updateProjectLocation)) {

                // get the top left, top right, bottom left and bottom right coordinates of the map
                var bounds = mapholder.getBounds();

                // get the top left and bottom right coordinates
                coords.push({lat: bounds.getNorthWest().lat, lng: bounds.getNorthWest().lng})
                coords.push({lat: bounds.getNorthEast().lat, lng: bounds.getNorthEast().lng})
                coords.push({lat: bounds.getSouthEast().lat, lng: bounds.getSouthEast().lng})
                coords.push({lat: bounds.getSouthWest().lat, lng: bounds.getSouthWest().lng})

            } else {

                // loop through the mapLayers and get the coordinates
                mapLayers.map((element) => {
                    element.latlngs.map((latlng) => {
                        latlng.map((coordinates) => {
                            coords.push({lat: coordinates.lat, lng: coordinates.lng});
                        })
                    })
                })
            }

            // check if the coords array is not empty and send a request to the API to extract the data
            if (coords.length > 0) {
                if(props.addProject || props.updateProjectLocation) {
                    // move map to the center of the polygon and make sure the polygon is fit to the screen
                    mapholder.flyToBounds(coords, { padding: [50, 50], animate: true, duration: 0.15 });
                    // console.log(coords)
                    setTimeout(() => {
                        // Now that zoom and pan are completed, capture the map as an image
                        // const map = document.getElementById('mapHolder');
                        // get body
                        const map = document.getElementsByTagName('body')[0];
                        const url = props.addProject ? '/project/create' : '/project/update';
                        // remove search bar from the map
                        document.getElementsByClassName('map-search-autocomplete false  false css-2b097c-container')[0].style.visibility = 'hidden';
                        
                        // get all svg elements and hide
                        var svg = document.getElementsByTagName('svg');
                        for (var i = 0; i < svg.length; i++) {
                            svg[i].style.visibility = 'hidden';
                        }

                        html2canvas(map, {useCORS: true, allowTaint: true}).then((canvas) => {
                            const imgData = canvas.toDataURL('image/png');

                            const body = {}
                            if (props.addProject) {
                                body['coords'] = coords;
                                body['image_base64'] = imgData;
                                body['project_type'] = props.projectType;
                            } else {
                                body['project_id'] = props.projectDetail.id;
                                body['coords'] = coords;
                                body['image_base64'] = imgData;
                                body['update_type'] = "location";
                                body['project_type'] = props.projectType;
                            }
                            // send request to the API to create the project
                            httpClientPy
                            .post(url, body)
                            .then((response) => {
                                // console.log(response.data.project_id);
                                if (props.addProject) {
                                    navigate('/project', { state: {id: response.data.project_id} });
                                } else {
    
                                    navigate('/project', { state: {id: props.projectDetail.id} });
                                }
                            }).catch((error) => {
                                // console.log(error.response.data.detail)
                                props.updatePolygonState({current_step: 6, current_text: error.response.data.detail, current_text_add: "", reset: false})
                            })
                            document.getElementsByClassName('map-search-autocomplete false  false css-2b097c-container')[0].style.visibility = 'visible';
                            for (var i = 0; i < svg.length; i++) {
                                svg[i].style.visibility = 'visible';
                            }
                        })
                    }, 500);
                } else {
                    // console.log(props.projectDetail.id)
                    httpClientPy
                    .post(`/geo/extract/borehole/polygon`, {
                        data: coords,
                        project_id: props.projectDetail.id
                    })
                    .then((response) => {
                        // if the response code is 2 means there were no boreholes, so trigger reset and request new polygon
                        if (response.data.code === 2) {
                            props.updatePolygonState({current_step: 5, current_text: "Sorry... there's no public data available in your area right now", current_text_add: "Try our borehole digitiser on your PDF geotech reports instead", reset: false})
                        } else {
                            // update to step 4 and set the polygonCompleted to true
                            props.updatePolygonState({current_step: 4, current_text: "Loading all public data in the area...", current_text_add: "", current_text_add2: "Loading all public data can take up to 30 minutes.", reset: false})
                            setPolygonCompleted(true)
                        }
                    })
                }
            }
        }
    }, [polygonCompleted, props.polygonState, polyRef, mapLayers, polygonReset])

    return (
        <FeatureGroup>
            <EditControl
                position="topright"
                onCreated={_onCreate}
                onMounted={_onMounted}
                draw={{
                    rectangle: false,
                    polyline: false,
                    circle: false,
                    circlemarker: false,
                    marker: false,
                    polygon: {
                        allowIntersection: false,
                        shapeOptions: {
                            color: "purple",
                            weight: 3,
                        },
                    },
                }}
                edit={{
                    edit: true,
                    remove: true,
                }}
            />
        </FeatureGroup>
    );
};

export default PolygonBhExtract;
