import { useState } from "react";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader } from "reactstrap";


const WorkFlowCardDropDown = (props) => {

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [displayModal, setDisplayModal] = useState(false);

    const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

    const dismissError = () => {
        props.setError(null);
        setDisplayModal(!displayModal);
    }

    return (

        <>

            <Dropdown isOpen={dropdownOpen} direction="down" className="project-dropdown-modal" toggle={toggleDropdown} onClick={(event) => event.stopPropagation()}>
                <DropdownToggle className="text-start border-0 text-dark" tag="div">
                    <span><FontAwesomeIcon icon={faEllipsis}/></span>
                </DropdownToggle>
                <DropdownMenu className="project-dropdown-modal" container="body">
                    <DropdownItem onClick={()=>setDisplayModal(!displayModal)}>Delete template</DropdownItem>
                </DropdownMenu>
            </Dropdown>
            <Modal isOpen={displayModal} toggle={()=>setDisplayModal(!displayModal)}>
                <ModalHeader>
                <h4 className="title-bold">{!props.error ? 'Delete this project' : 'Something went wrong.'}</h4>
                </ModalHeader>
                <ModalBody>
                    {!props.error ?
                    <>
                        <div>Are you sure you want to delete this template? 
                            <p className="text-danger">This action will remove all the data associated with this workflow. This action cannot be undone.</p>
                        </div>
                        <div className="d-flex justify-content-end mt-4">
                            <button className="btn btn-danger" onClick={()=>props.triggerDelete(props.id)}>Delete</button>
                            <button className="btn btn-outline-secondary ms-2" onClick={()=>setDisplayModal(!displayModal)}>Cancel</button>
                        </div>
                    </> :
                    <>
                        <div className='d-flex justify-content-center flex-column align-items-center'>
                            <div>{props.error}</div>
                            <button className="btn btn-outline-secondary ms-2" onClick={()=>dismissError()}>Okay</button>
                        </div>
                    </>
                    }
                </ModalBody>
            </Modal>

        </>
    )

}

export default WorkFlowCardDropDown;