import { Navbar } from "react-bootstrap";
import Button from "../../shared/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarsProgress, faCaretDown, faCheck, faClockRotateLeft, faDiagramProject, faEdit, faFloppyDisk, faPlay, faTableCells, faX } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import FlowViewer from "./FlowViewer";
import { toast } from "react-toastify";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, Tooltip } from "reactstrap";
import httpClientPy from "../../../utils/httpClientPy";
import SaveWorkflow from "./SaveWorkflow";

const WorkflowNavbar = (props) => {

    const [workflowName, setWorkflowName] = useState(null);
    const [editName, setEditName] = useState(false);
    const [flowSideToggler, setFlowSideToggler] = useState({history: false, running: false});
    const [saveAsDropdown, setSaveAsDropdown] = useState(false);
    const [toggleSaveFinal, setToggleSaveFinal] = useState(false);
    const [saveType, setSaveType] = useState('new');
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const toggleSaveAsDropdown = () => setSaveAsDropdown(!saveAsDropdown);
    const toggleSaveFinalModal = () => setToggleSaveFinal(!toggleSaveFinal);

    const toggle = () => setTooltipOpen(!tooltipOpen);


    const handleBtnClick = (type) => {
        if (type === 'editor') {
            props.setSelectedBtn('editor');
            props.setAction('edit', props.workFlowId, props.workFlowRunId)
        } else if (type === 'outputs') {
            props.setSelectedBtn('outputs');       
        }
    }

    const handleChangeWorkflowName = (e) => {
        setWorkflowName(e.target.value);
    }

    const handleSaveName = () => {
        if (workflowName.length < 1) {
            toast.error('Workflow name cannot be empty', { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
            return;
        }
        let workflow = props.workflow;
        workflow.name = workflowName;
        props.setWorkflow(workflow);
        setEditName(!editName);
    }

    const handleSaveWorkflow = async (type) => {

        setLoading(true);

        if (props.workflow.nodes.length < 1) {
            toast.error('Workflow cannot be empty', { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
            return;
        }

        if (props.workflow.edges.length < 1) {
            toast.error('Workflow cannot be empty', { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
            return;
        }

        setSaveType(type);

        httpClientPy.post('/workflow/validate', {
            workflow: props.workflow
        }).then((response) => {
            if (response.data.error_nodes.length > 0) {
                props.setNodesError(response.data.error_nodes);
                toast.error(
                    <div>
                        {response.data.errors.split('\n\n').map((error, index) => (
                            <div key={index}>{error}</div>
                        ))}
                    </div>,
                    { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 }
                );
                setTimeout(() => {
                    props.setNodesError([]);
                }, 6000);
            } else {
                toggleSaveFinalModal();
            }
        }).catch((error) => {
            toast.error('Error validating workflow', { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
        }).finally(() => {
            setLoading(false);
        });
    }

    const sendWorkflowRun = async (nodes_data, edges_data, files) => {

        props.setRunTriggered(true);

        // Create FormData and append payload
        const formData = new FormData();
        const payload = {
            nodes: nodes_data,
            edges: edges_data,
            project_id: props.project.id,
            workflow_id: props.workFlowId
        };
        formData.append('request', JSON.stringify(payload));
        
        // Append files if any
        if (files && files.length > 0) {
            files.forEach(file => {
                formData.append('files', file);
            });
        }

        // Send POST request with FormData
        httpClientPy.post('/workflow/run/new', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => {
            props.setAction('run', props.workFlowId, response.data.run_id);
            props.setSelectedBtn('run');
            props.setRunTriggered(false);
        })
        .catch(error => {
            toast.error(error.response.data.detail, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000
            });
        });
    }

    const handleRunFlow = async () => {

        setLoading(true);

        let fileNode = props.workflow.nodes.filter(node => node.data.node === 'selector')
        
        if (fileNode[0].data.input.length > 0) {
            if (fileNode[0].data.input[0] == '') {
                toast.error('Please select or upload file(s) to run the workflow', { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
                setLoading(false);
                return;
            }
        }
        
        if (fileNode[0].data.input.length === 0 && props.globalFileToUpload.length === 0) {
            toast.error('Please select or upload file(s) to run the workflow', { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
            setLoading(false);
            return;
        }

        httpClientPy.post('/workflow/validate', {
            workflow: props.workflow
        }).then((response) => {
            if (response.data.error_nodes.length > 0) {
                props.setNodesError(response.data.error_nodes);
                toast.error(
                    <div>
                        {response.data.errors.split('\n\n').map((error, index) => (
                            <div key={index}>{error}</div>
                        ))}
                    </div>,
                    { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 }
                );
                setTimeout(() => {
                    props.setNodesError([]);
                }, 6000);
            } else {
                sendWorkflowRun(props.workflow.nodes, props.workflow.edges, props.globalFileToUpload);
            }
        }).catch((error) => {
            toast.error('Error validating workflow', { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
        }).finally(() => {
            setLoading(false);
        });

    }

    useEffect(() => {
        if (props.workflow.name) {
            setWorkflowName(props.workflow.name);
        }
    }, [props.workflow.name]);

    return (
        <>
            <Navbar className='w-100 workflow-navbar'>
                <div className="d-flex ms-2 justify-content-start">
                    {!editName ? (
                        <>
                            <span className="d-block d-md-flex text-center text-md-start align-items-center pb-2 pb-md-0">
                                <h4 
                                    className="title-bold d-inline mb-0" 
                                    style={{ overflowX: 'auto', whiteSpace: 'nowrap', maxWidth: '600px' }}
                                >
                                    <b>{workflowName}</b>
                                </h4>
                            </span>
                        </>
                    ) : (
                        <>
                            <input type="text" className="workflow-navbar-edit-form" value={workflowName} autoFocus={true}
                                onChange={(e) => handleChangeWorkflowName(e)} />
                            <Button label={<><FontAwesomeIcon icon={faCheck} size="sm" /><span className="ms-2">Save</span></>}
                                    className='btn btn-sm btn-primary ms-2' 
                                    onClick={() => handleSaveName()} 
                                    loading={loading} />
                        </>
                    )}
                </div>
                <div className="w-100 justify-content-end d-flex">
                    
                    {props.action === 'edit' ? (
                        loading ? (
                            <Button className='btn btn-sm btn-light border me-2'
                                    loading={loading}
                            />
                        ) : (
                            <>
                            {props.superAdmin ? (
                                <Dropdown 
                                    isOpen={saveAsDropdown}
                                    toggle={toggleSaveAsDropdown}
                                    className='d-inline-block'
                                >
                                    <DropdownToggle className='btn btn-sm btn-light border me-2'>
                                        <FontAwesomeIcon icon={faFloppyDisk}/>
                                        <FontAwesomeIcon className="ms-2" icon={faCaretDown}/>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => handleSaveWorkflow('overwrite')}>Overwrite</DropdownItem>
                                        <DropdownItem onClick={() => handleSaveWorkflow('new')}>New Copy</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            ) : (
                                !props.workflow.is_digitsation && (
                                    <Dropdown 
                                        isOpen={saveAsDropdown}
                                        toggle={toggleSaveAsDropdown}
                                        className='d-inline-block'
                                    >
                                        <DropdownToggle className='btn btn-sm btn-light border me-2'>
                                            <FontAwesomeIcon icon={faFloppyDisk}/>
                                            <FontAwesomeIcon className="ms-2" icon={faCaretDown}/>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {!props.workflow.is_public && 
                                                <DropdownItem onClick={() => handleSaveWorkflow('overwrite')}>Overwrite</DropdownItem>
                                            }
                                            <DropdownItem onClick={() => handleSaveWorkflow('new')}>New Copy</DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                )
                            )}
                            </>
                        )
                    ) : props.action === 'create' ? (
                        <Button label={<><FontAwesomeIcon icon={faFloppyDisk} size="sm" /><span className="ms-2">Save</span></>} 
                                className='btn btn-sm btn-light border me-2'
                                onClick={() => handleSaveWorkflow('new')} loading={loading}
                        />
                    ) : ( 
                        null
                    )}
                    {props.action !== 'create' &&
                        <>
                            <Button label={<><FontAwesomeIcon icon={faClockRotateLeft} size="sm" /><span></span></>} 
                                    className='btn btn-sm btn-light border me-2'
                                    onClick={() => setFlowSideToggler({history: true, running: false})} loading={loading}
                            />
                        </>
                    }

                    <FlowViewer handleRunFlow={handleRunFlow}
                                flowSideToggler={flowSideToggler}
                                setFlowSideToggler={setFlowSideToggler} 
                                project_id={props.project.id}
                                setAction={props.setAction}
                                workFlowId={props.workFlowId}
                                setSelectedBtn={props.setSelectedBtn}
                    />
                    <Button label={<><FontAwesomeIcon icon={faX} size="sm" /></>}
                            className='btn btn-sm btn-light ms-1 me-2' 
                            onClick={() => props.defaultAction()} 
                    />
                </div>
                <div className={`${props.selectedBtn !== 'outputs' ? 'overhang-buttons bg-light border' : 'non-overhang-buttons'} p-2`}>
                    <Button label={<><FontAwesomeIcon icon={faDiagramProject} size="sm" /><span className="ms-2">Editor</span></>} 
                            disabled={loading}
                            className={`${props.selectedBtn !== 'editor' && props.selectedBtn !== 'run' ? 'btn btn-sm btn-light border' : 'workflow-navbar-selected-btn'}`}
                            onClick={() => props.selectedBtn !== 'editor' && props.selectedBtn !== 'run' ? handleBtnClick('editor') : null}
                    />
                    <Button label={<><FontAwesomeIcon icon={faTableCells} size="sm" /><span className="ms-2">Outputs</span></>} 
                            disabled={props.workFlowRunId === null || props.workFlowRunId === undefined || props.workFlowRunId === -1 || loading}
                            className={`${props.selectedBtn !== 'outputs' ? 'btn btn-sm btn-light border' : 'workflow-navbar-selected-btn'}`}
                            onClick={() => props.selectedBtn !== 'outputs' ? handleBtnClick('outputs') : null}
                    />
                    {props.action !== 'create' &&
                        <>
                            <Button id="runBtn" label={<><FontAwesomeIcon icon={faPlay} size="sm" /><span className="ms-2">Run</span></>} 
                                        className= 'btn btn-sm btn-primary border'
                                        onClick={handleRunFlow}
                                        disabled = {props.selectedBtn !== 'editor'}
                                        loading={loading}
                            />
                        </>
                    }
                </div>
            </Navbar>
            <Modal isOpen={toggleSaveFinal} toggle={toggleSaveFinalModal} size="lg">
                <SaveWorkflow toggleSaveFinalModal={toggleSaveFinalModal} 
                              saveFinal={toggleSaveFinal}
                              workflow={props.workflow}
                              setWorkflow={(data) => props.setWorkflow(data)}
                              workflowId={props.workFlowId}
                              saveType={saveType}
                              project={props.project}
                              setAction={props.setAction}
                              superAdmin={props.superAdmin}
                />
            </Modal>
        </>
    )
}

export default WorkflowNavbar;