import {useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import httpClientPy from "../../../utils/httpClientPy";
import { Spinner } from "reactstrap";
import SideBar from "../../project/SideBar";
import OutputListTable from "./OutputListTable";
import FilterSelector from "../main/inner/FilterSelector";

const WorkflowOutputList = (props) => {

    const location = useLocation();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [project, setProject] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [admin, setAdmin] = useState(false);
    const [categories, setCategories] = useState([]);
    const [workflowNames, setWorkflowNames] = useState([]);
    const [categoryFilters, setCategoryFilters] = useState([]);
    const [namesFilters, setNamesFilters] = useState([]);

    const getProjectData = (id) => {
        httpClientPy.get(`/project/${id}`)
          .then((response) => {
            setProject(response.data.project);
            setLoading(false);
          })
          .catch((err) => {
            setError(true);
            setErrorMessage(err.response.data.detail);
            setLoading(false);
          }
        );
    }

    const getWorkflowMeta = async (project_id) => {
        try {
            const response = await httpClientPy.get(`/workflows/runs/outputs/filters/meta?project_id=${project_id}`);
            setCategories(response.data.categories);
            setWorkflowNames(response.data.names);
        } catch (err) {
            setError(true);
            setErrorMessage(err.response.data.detail);
            setLoading(false);
        }
    }

    const handleWorkflowClick = (action, workflow_id, run_id, is_digitise, name) => {
        navigate('/project/workflows', { state: {project_id: project.id, 
                                                 workflow_id: workflow_id, 
                                                 run_id: run_id, 
                                                 action: action,
                                                 is_digitise: is_digitise,
                                                 name: name,
                                                 source_page: 'outputs'
                                                } 
                                        }
                );
    }
    
    useEffect(() => {
        httpClientPy.get(`/user`).then((response) => {
            let userList = response.data
            if (userList.is_enterprise) {
                userList.allow_digitise = userList.enteprise_is_digitiser
                userList.allow_civilsgpt = userList.enterprise_is_civilsgpt
                userList.allow_drawing = userList.enterprise_is_drawing
                userList.allow_workflow = userList.enterprise_is_workflow
            } else {
                userList.allow_digitise = userList.subscription_details ?  userList.subscription_details.is_digitise : false
                userList.allow_civilsgpt = userList.subscription_details ? userList.subscription_details.is_civilsgpt : false
                userList.allow_drawing = userList.subscription_details ? userList.subscription_details.is_drawing : false
                userList.allow_workflow = userList.subscription_details ? userList.subscription_details.is_workflow : false
            }
            setUserInfo(userList)
            setAdmin(userList.enterprise_admin)
            
        }).catch((err) => {
            setError(true);
            setErrorMessage(err.response.data.detail);
            setLoading(false);
        });
    }, [])

    useEffect(() => {
    
        if (location.state === null) {

            setError(true);
            setErrorMessage("Project not found");
            setLoading(false);

        } else {
            
            getWorkflowMeta(location.state.project_id);
            getProjectData(location.state.project_id);
            
        }
    }, []);        

    return (
        <>
            {!loading ? (
                !error ? (
                    <>  
                        <div className="d-flex">
                            <div id="sidebarParent" className='d-none d-md-block' style={{minWidth:'190px'}}>
                                <SideBar page={'detail'} 
                                        loading={loading}
                                        subpage={'workflows-outputs'} 
                                        type={null} 
                                        project={project} 
                                        chatEnabled={false}
                                        userInfo={userInfo}
                                        admin={admin}
                                        closeChat = {() => {}}
                                        setSubpage={() => {}}
                                        setType={() => {}}
                                        onComplete={() => getProjectData(project.id)} />
                            </div>
                            <div  className="flex-fill px-3 pe-lg-0 py-3" style={{maxWidth:'100vw'}}>
                                <div>
                                    <div className='container mt-0 mt-lg-2' >
                                        <div className='d-flex justify-content-between align-items-center mb-3'>
                                            <h3 className='title-bold mb-0'>Workflow Outputs</h3>
                                        </div>
                                        
                                        <div className="row py-1 g-1" style={{animation: 'fadeIn 1s'}}>
                                            <div className="d-flex align-items-center gap-3">
                                                <FilterSelector selections={categories} cat={true} 
                                                                filters={categoryFilters} setFilters={setCategoryFilters} />
                                                <FilterSelector selections={workflowNames} cat={false} 
                                                                filters={namesFilters} setFilters={setNamesFilters} />
                                            </div>
                                            <OutputListTable project={project}
                                                             categoryFilters={categoryFilters}
                                                             namesFilters={namesFilters}
                                                             setCategoryFilters={setCategoryFilters}
                                                             setAction={(action, id, 
                                                                         run_id, is_digitise, name) => handleWorkflowClick(action, id, 
                                                                                                                           run_id, is_digitise, name)}
                                            />
                                        </div>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className='d-flex justify-content-center align-items-center' style={{ height: '50vh' }}>
                        <p>{errorMessage}</p>
                    </div>
                )
            ) : (
                <div className='d-flex justify-content-center align-items-center' style={{ height: '50vh' }}>
                    <Spinner color='primary' />
                </div>
            )}
        </>
    );
}

export default WorkflowOutputList;
