import { useRef, useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { FileUploader } from "react-drag-drop-files";
import { UncontrolledAlert } from "reactstrap";


const FileUploaderModal = (props) => {

    const [errorCode, setErrorCode] = useState(null);

    const fileInputRef = useRef(null);

    const handleFileAdded = (files) => {

        let list_files = props.filesUploaded.length > 0 ? props.filesUploaded : [];
        let selected_files = props.filesSelected
    
        for (var i = 0; i < files.length; i++) {
            if (list_files.filter(file => file.name === files[i].name).length > 0) {
                continue;
            }
            list_files.push(files[i]);
            selected_files.push({file_name: files[i].name });
        }
        
        props.setFilesUploaded(list_files);
        props.setFilesSelected(selected_files);

        if (fileInputRef.current) {
            fileInputRef.current.clear();
        }

        props.setToggleFileUploader(false);
    }

    if (errorCode) {
        setTimeout(() => {
            setErrorCode(null);
        }, 3000);
    }

    return (
        <Modal isOpen={props.toggleFileUploader} toggle={() => props.setToggleFileUploader(!props.toggleFileUploader)} size='lg' centered>
        <ModalHeader toggle={() => props.setToggleFileUploader(!props.toggleFileUploader)}>Upload document(s) to use with this agent</ModalHeader>
        <>
            <div className="bg-white border rounded shadow-sm d-flex flex-column flex-grow-1" style={{overflowY:'auto'}}>
                <div className="p-3">
                    <FileUploader
                        classes={`drag-drop-uploader w-100 mb-2`}
                        style={{ minWidth: "100%" }}
                        label="Drop files here"
                        hoverTitle="Drop files here"
                        name="file"
                        types={["pdf"]}
                        handleChange={handleFileAdded}
                        maxSize={300}
                        onSizeError={()=> setErrorCode(6)}
                        onTypeError={()=> setErrorCode(7)}
                        multiple={true}
                        ref={fileInputRef}
                    />

                    {errorCode === 6 ? (
                        <UncontrolledAlert color="danger" className="mb-0">
                            <span className="title-bold">File size is too large</span>
                        </UncontrolledAlert>
                    ) : errorCode === 7 ? (
                        <UncontrolledAlert color="danger" className="mb-0">
                            <span className="title-bold">File type is not supported</span>
                        </UncontrolledAlert>
                    ) : null}
                </div>
            </div>
        </>
    </Modal>
    )

}

export default FileUploaderModal;