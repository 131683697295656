import React, { useCallback, useEffect, useState, useRef } from "react";
import { MapContainer} from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchBar from "./SearchBar";
import MapData from "./MapData";
import LeafletRulerContainer from "../mapPlugins/LeafletRulerContainer.js"
import SideMenu from "./SideMenu";
import FooterBar from "./onboarding/FooterBar";
import ContextMenu from "./ContextMenu.js"
import { Toast, ToastHeader, ToastBody, Card, CardBody} from "reactstrap";
import localStorageService from "../../utils/localStorageService";
import httpClientPy from "../../utils/httpClientPy";
import { useLocation, useNavigate } from "react-router-dom";

import { faEllipsis, faLayerGroup, faCaretLeft, faSatellite, faLocationDot, faLocationCrosshairs, faRuler, faCopy, faCheck, faMap} from "@fortawesome/free-solid-svg-icons";
library.add(faLayerGroup, faCaretLeft, faSatellite, faLocationDot, faLocationCrosshairs, faRuler, faCopy, faCheck, faMap);



const MapPage = () => {

  //mapLayer controls which layer is displayed on the map, either satellite, roadmap or all. 'All' loads both satellite and roadmap, I needed to load both due to a leaflet google maps limitation 
  const [mapLayer, setMapLayer] = useState('roadmap');
  //searchgeorecord is used to highlight a particular borehole on the map, this highlighting animation is controlled in mapdata, but it is toggled in the sidemenu, datapage where it is activated on a button click
  const [searchGeoRecord, setSearchGeoRecord] = useState();
  //sidemenutoggle controls whether the sidemenu is displayed, in sidemenu we also listen for an escape key press to toggle this
  const [sideMenuToggle, setSideMenuToggle] = useState(false);
  //updateddata is used to trigger the data page to call the function 'getboreholelogs' so that when a user clicks 'extract data' on the borehore modal on the mapdata page we then update the datapage to include that file 
  const [updatedData, setUpdatedData] = useState(false);
  //virtualBorehole is triggered in the side menu in the planning page, it is then passed through the mapdata page into the virtualborehole where is controls the cursor animation and calls the 3D modelling algorithm
  const [virtualBorehole , setVirtualBorehole] = useState(false);
  //fixVirtualBorehole triggers inside virtualborehole which is inside the mapdata page, this freezes the cursor animation and triggers an update with the threejs animation in the planningpage component
  const [fixVirtualBorehole , setFixVirtualBorehole] = useState(false);
  // virtualBoreholeData is a dictionary of data for creating the threejs 3D animation, the data is added in the virtualborehole component
  const [virtualBoreholeData, setVirtualBoreholeData] = useState();

  // cursorLat and cursorLng are updated in the mapdata component under the useMapEvents
  const [cursorLat, setCursorLat] = useState('');
  const [cursorLng, setCursorLng] = useState('');
  //zoom sets the zoom level for the map
  const [zoomMarkers, setZoomMarkers] = useState([15,0]);
  //addBorehole controls when the manual borehole record form is displayed it is trigered in the sidemenu under the datapage
  const [addBorehole , setAddBorehole] = useState(false);
  // updateBorehole controls when the update manual borehole record form is displayed it is trigered in the datapage
  const [updateBorehole , setUpdateBorehole] = useState(false);
  //fixBorehole controls when the manual borehole record form is displayed it is triggered in the mapdata page under the useMapEvents
  const [fixBorehole , setFixBorehole] = useState(false);

  // displaySidemenu controls the visibility of sidemenu
  const [displaySidemenu, setDisplaySidemenu] = useState(true);
  // enable or disable the polygon drawing tool
  const [drawPolygon, setDrawPolygon] = useState(false);
  // hashmap to store the polygon states
  const [polygonState, setPolygonState] = useState({'current_step': 1, 'current_text': 'Draw an area around public boreholes and request any boreholes inside', current_text_add: "", reset: false});
  //display demo modal
  const [firstAlert, setFirstAlert] = useState(false);
  const [secondAlert, setSecondAlert] = useState(false);

  //showmodal
  const [showModal, setShowModal] = useState(false);
  //state for displaying sectioncut
  const [toggleSectionCut, setToggleSectionCut] = useState();
  // state to check if onboarding steps
  const [onBoarded, setOnBoarded] = useState({'step1': false, 'skip1': false});
  // state to check if onboarding is done
  const [onBoardingDone, setOnBoardingDone] = useState(true);
  // state to check number of ready markers in the map bound
  // check if user is a new user
  const [newUser, setNewUser] = useState(false);
  // check if selected location is in UK
  const [isUK, setIsUK] = useState(null);
  // check if user clicked on search button
  const [searchClicked, setSearchClicked] = useState(false);
  // check user access type 1: Full, 2: Partial, 0: Block/Default
  const [userAccessType, setUserAccessType] = useState(0);
  const [selectionRadiusSlider, setSelectionRadiusSlider] = useState(400);
  // variable to store if current process is to add project
  const [addProject, setAddProject] = useState(null);
  // variable to store if project is to be updated location
  const [updateProjectLocation, setUpdateProjectLocation] = useState(null);
  // variable to store the new project type 0: area specified, 1: area not specified
  const [projectType, setProjectType] = useState(null);
  // variable to store current project if any
  const [project, setProject] = useState(null);
  // variable to store current record
  const [geoRecord, setGeoRecord] = useState(null);
  // variable to store current record position action
  const [geoRecordPosition, setGeoRecordPosition] = useState(null);
  const [polygonCenter, setPolygonCenter] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(null);
  const [mapLoading, setMapLoading] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();

  // useEffect(() => {
  //   httpClientPy.get('/user/check/access')
  //   .then((response) => {
  //     setUserAccessType(response.data.allow)
  //   })
  //   .catch((error) => {
  //     console.log(error)
  //   })
  // }, [])

  useEffect(() => {
    // get prop that is sent from usenavigate from other route
    const { state } = location;
    if(state != null) {
      const { project, project_type } = state;
      if(project) {
        setAddProject(true);
        setProjectType(project_type);
        setGeoRecord(null);
        setProject(null);
        setGeoRecordPosition(null);
      } else {
        setAddProject(false);
      }
    } else {
      setAddProject(false);
    }
  }, [])

  useEffect(() => {
    if(addProject === false || addProject === null) {
      const { state } = location;
      if(state != null) {
        const { project_detail, geo_record, project_update_location, project_type } = state;
        const { position_action } = state;
        
        if(position_action) {
          setGeoRecordPosition(position_action);
          toggleUpdateBorehole(true)
        }

        if(geo_record) {
          setGeoRecord(geo_record);
        } else {
          setGeoRecord(null);
        }

        if(project_detail) {
          setProject(project_detail);
        }

        if(project_update_location) {
          setUpdateProjectLocation(true);
          setProjectType(project_type);
          setDrawPolygon(true)
          if(project_type === 0) {
            setPolygonState({'current_step': 1, 'current_text': 'Draw a project area', current_text_add: "", reset: false})
          }

        }
      } else {
        navigate('/projects')
      }
    }
  }, [addProject])



  useEffect(() => {
    if(fixBorehole && geoRecordPosition === 'update') {
      httpClientPy.put(`/geo/record/update/position?geo_record_id=${geoRecord.id}`, {
          easting: cursorLng,
          northing: cursorLat,
          top: 2
        })
        .then((response) => {
          toggleUpdateBorehole(false)
          searchMap(cursorLat, cursorLng, geoRecord.id)
          setGeoRecordPosition(null)
          setCursorLat('')
          setCursorLng('')
        });
    }
  }, [fixBorehole])



  function toggleAddBorehole(status){
    setAddBorehole(status)
    setFixBorehole(false)
  }

  function toggleUpdateBorehole(status){
    setUpdateBorehole(status)
    setFixBorehole(false)
  }

  function updateBoreholeList(boreholeData) {
    setVirtualBoreholeData(boreholeData)
  }

  function updatePolygonState(polyState) {
    setPolygonState(polyState)
  }

  function toggleFirstAlert() {
    if(firstAlert){
      localStorageService.setItem("demo2_viewed", true);
    }
    setFirstAlert(!firstAlert)
  }

  function toggleSecondAlert() {
    setSecondAlert(!secondAlert)
  }

  function togglePolygonDrawn() {
    //localStorageService.setItem("polygon_drawn", true);
    setDrawPolygon(false)
  }

  // function updateOnBoardingState(state) {
  //   setOnBoarded(state)
  //   if(state.step1) {
  //     localStorageService.setItem("onboarded", true);
  //     setOnBoardingDone(true)
  //   }
  // }

  // useEffect(() => {
  //   if(onBoardingDone && !newUser) {
  //     updateOnBoardingState({'step1': false, 'skip1': false})
  //   }
  // }, [onBoardingDone, newUser])

  function onUpdateZoomMarkers(zoomMarkers){
    setZoomMarkers(zoomMarkers)
  }

  function cancelSearchGeoRecord(){
    setSearchGeoRecord(undefined)
  }


  //track the coordinates of the cursor location  
  const updateCursorCoords = (lat,lng) =>{
    setCursorLat(Math.round(lat * 100000) / 100000)
    setCursorLng(Math.round(lng * 100000) / 100000)
  }




  // toggle fixing the virtual borehole
  function toggleMenuVirtualBorehole(status){
    setVirtualBorehole(status);
    if (status==false){
      setFixVirtualBorehole(false)
      setVirtualBoreholeData()
    }
    else if (virtualBorehole){
      setFixVirtualBorehole(true)
    }
  }


  function toggleIsUK(state){
    setIsUK(state)
  }

  function toggleSearchClicked(state){
    setSearchClicked(state)
  }


  let styleHolder = [
    {
      featureType: "administrative",
      elementType: "all",
      stylers: [
        {
          saturation: "-100",
        },
      ],
    },
    {
      featureType: "administrative.province",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "all",
      stylers: [
        {
          saturation: -100,
        },
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "road",
      elementType: "all",
      stylers: [
        {
          saturation: "-100",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "all",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          saturation: -70,
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels",
      stylers: [
        {
          lightness: -25,
        },
        {
          saturation: -100,
        },
      ],
    },
  ]

  //when searching for a geo record close the side menu and highlighted the record location on map
  const searchMap = (lat, lng, id)  =>{
    setSideMenuToggle(false)
    const newState = {
      location: [lat, lng],
      id: id,
      zoom: 18,
    };
    setSearchGeoRecord(newState);
  }


  // update data state after data is requested
  const onDataRequest = () => {
    setUpdatedData(!updatedData)
  }
  



  function onToggleSectionCut(state){
    setToggleSectionCut(state)
  }

  function toggleDrawArea() {
    setDrawPolygon(true);
    if(addProject || updateProjectLocation) {
      if(projectType === 0) {
        setPolygonState({'current_step': 1, 'current_text': 'Draw a project area', current_text_add: "", reset: false})
      } else {
        setPolygonState({'current_step': 2, 'current_text': 'Search for your project location and click confirm area', current_text_add: "", reset: false})
      }
    } else {
      setPolygonState({'current_step': 1, 'current_text': 'Draw an area around public boreholes and request any boreholes inside', current_text_add: "", reset: false})
    }
  }

  const handleMapReady = () => {
    // Perform any actions you need after the map is loaded
    setMapLoading(false)
  };

  function changeMapLayer(layer){
    setMapLayer(layer)
  }

  return (
    <div>
            <MapContainer 
              id="mapHolder" 
              className={`map`} 
              zoomControl={false} 
              dragging={false} 
              whenReady={handleMapReady}
            >
                {project &&
                <SearchBar addBorehole={addBorehole} 
                          onToggleSearch={(status)=>{setDisplaySidemenu(!status)}} 
                          toggleIsUK = {(state) => toggleIsUK(state)}
                          toggleSearchClicked={(state)=>{toggleSearchClicked(state)}}
                          projectDetails = {project}
                          />
                }

              {(mapLayer === 'roadmap' || mapLayer === 'all') &&
                  <ReactLeafletGoogleLayer
                    type="roadmap"
                    styles={styleHolder}
                    useGoogMapsLoader={false}
                    apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                  >
                  </ReactLeafletGoogleLayer>
                }
                {(mapLayer === 'satellite' || mapLayer === 'all')  &&
                  <ReactLeafletGoogleLayer
                    type="satellite"
                    styles={styleHolder}
                    useGoogMapsLoader={false}
                    apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                  >
                  </ReactLeafletGoogleLayer>
                }

                
                <a type="button" className="mapLayerSelected" onClick={()=>changeMapLayer(mapLayer !== 'satellite' ? 'satellite' : 'roadmap')}>
                  <div data-content={mapLayer === 'satellite' ? 'Map' : 'Satellite'} className="mapLayerImageContainer">
                    <img
                      src={mapLayer === 'satellite' ? "/assets/mapLayerIcon.svg" : "/assets/satelliteLayerIcon.svg"}
                      alt="Digitise your geological records automatically"
                      className="d-inline layerIcon"
                    />
                  </div>
                </a>

                {(addProject !== null || updateProjectLocation) &&
                <MapData 
                        onBoarded = {onBoarded}
                        onBoardingDone = {onBoardingDone}
                        newUser = {newUser}
                        fixBorehole={fixBorehole} 
                        addBorehole={addBorehole} 
                        updateBorehole={updateBorehole} 
                        fixVirtualBorehole={fixVirtualBorehole} 
                        virtualBorehole={virtualBorehole} 
                        mapLayer={mapLayer} 
                        searchGeoRecord={searchGeoRecord}
                        geoCutSection = {toggleSectionCut}
                        polygonDrawn = {drawPolygon}
                        polygonState = {polygonState}
                        searchClicked = {searchClicked}
                        selectionRadiusSlider = {selectionRadiusSlider}
                        addProject = {addProject}
                        updateProjectLocation = {updateProjectLocation}
                        projectType = {projectType}
                        projectDetails = {project}
                        geoRecord = {geoRecord}
                        geoRecordPosition = {geoRecordPosition}
                        cursorLat={cursorLat} 
                        cursorLng={cursorLng}
                        mapLoading={mapLoading}
                        // updateOnBoardingState = {(state) => updateOnBoardingState(state)}
                        onToggleSectionCut= {(state)=>onToggleSectionCut(state)}
                        onShowModal = {(status)=>{setShowModal(status)}}
                        onFixBorehole={(status)=>setFixBorehole(status)} 
                        onUpdateZoomMarkers={(zoom)=>onUpdateZoomMarkers(zoom)} 
                        updateBoreholeList={(boreholeData)=>updateBoreholeList(boreholeData)} 
                        createVirtualBorehole={(status)=>{toggleMenuVirtualBorehole(status)}} 
                        onDataRequest={()=>onDataRequest()} 
                        onTrackCursor={(lat,lng)=>{updateCursorCoords(lat,lng)}} 
                        updatePolygonState = {(polyState) => updatePolygonState(polyState)}
                        setGeoRecordPosition = {(position) => setGeoRecordPosition(position)}
                        toggleUpdateBorehole = {(status) => toggleUpdateBorehole(status)}
                        setGeoRecord = {(record) => setGeoRecord(record)}
                        />
                }

            </MapContainer>

            <Card 
                className={`shadow map-legend`}
            >
                <CardBody className="pt-2">
                  <div><small>Legend</small></div>
                  <div><img src="assets/borehole-red.svg" height={'20px'} alt="legend" /> <small>Uploaded borehole</small></div>
                  <div><img src="assets/borehole-black.svg" height={'20px'} alt="legend" /> <small>BGS borehole (AGS)</small></div>
                  <div><img src="assets/borehole-grey.svg" height={'20px'} alt="legend" /> <small>BGS borehole (PDF)</small></div>
                </CardBody>
            </Card>

        {(drawPolygon === false && addProject === false) &&
          <SideMenu 
                    readyMarkers={zoomMarkers[1]}
                    toggleSectionCut={toggleSectionCut}
                    zoom={zoomMarkers[0]} 
                    virtualBoreholeData={virtualBoreholeData} 
                    displaySidemenu = {displaySidemenu}
                    onBoardingDone = {onBoardingDone}
                    newUser = {newUser}
                    menuSelected="map"
                    classHolder={`mapControls`}
                    selectionRadiusSlider={selectionRadiusSlider}
                    addProject = {addProject}
                    updateProjectLocation = {updateProjectLocation}
                    projectDetails = {project}
                    cursorLat={cursorLat} cursorLng={cursorLng} fixBorehole={fixBorehole} 
                    fixVirtualBorehole={fixVirtualBorehole} updatedData={updatedData} 
                    onShowModal={(state)=>setShowModal(state)}
                    onCancelSearchGeoRecord={()=>{cancelSearchGeoRecord()}} 
                    addBorehole={(status) => {toggleAddBorehole(status)}} 
                    updateBorehole={(status) => {toggleUpdateBorehole(status)}} 
                    virtualBoreholeStatus={(status) => {toggleMenuVirtualBorehole(status)}} 
                    onToggleSectionCut= {(state)=>onToggleSectionCut(state)}
                    setNewUser = {(status) => setNewUser(status)}
                    updateSelectionRadius = {(radius) => setSelectionRadiusSlider(radius)}
                    drawArea={() => toggleDrawArea()} 
                    // onSearchGeoRecord={(lat, lng, id)=>searchMap(lat, lng, id)} 
                    />
        }
        {(drawPolygon === true || addProject === true) && 
          <FooterBar polygonState = {polygonState}
                     onBoarded = {onBoarded}
                     onBoardingDone = {onBoardingDone}
                     updatePolygonState = {(polyState) => updatePolygonState(polyState)}
                    //  updateOnBoardingState = {(state) => updateOnBoardingState(state)}
                     togglePolygonDrawn = {() => togglePolygonDrawn()}
                     addProject={addProject} 
                     updateProjectLocation={updateProjectLocation}
                     projectType={projectType}
                     project = {project} />
        }
      </div>  
    );
};

export default MapPage;

