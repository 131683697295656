import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faClock } from "@fortawesome/free-solid-svg-icons";
import { Modal, ModalBody, Spinner } from "reactstrap";
import Button from "../../shared/Button";
import { useNavigate } from "react-router-dom";

const FooterBar = (props) => {
    const navigate = useNavigate();
    const [modalVisibility, setModalVisibility] = useState(true);

    const submitPolygon = () => {
        props.updatePolygonState({current_step: 3, current_text: "", current_text_add: "", reset: false})
    }

    const resetPolygon = () => {
        props.updatePolygonState({current_step: 1, current_text: "Draw a polygon on the map to extract borehole data.", current_text_add: "", 
                                  reset: true})
    }

    const startOnBoarding = (trigger) => {
        if(trigger === 'Yes') {
            props.updateOnBoardingState({'step1': true, 'skip1': false})
        } else {
            props.updateOnBoardingState({'step1': true, 'skip1': true})
        }
    }

    const finalSubmit = () => {
        props.togglePolygonDrawn();
    }

    const toggleModal = () => {
        setModalVisibility(!modalVisibility);
    }
    
    return (
            <>
                    {props.polygonState.current_step === 2 ? (
                        <div className={`${props.projectType === 1 ? 'footer-bar-main' : 'footer-bar'}`}>
                            {props.projectType === 1 &&
                                <div className="mt-2">
                                    {props.updateProjectLocation ? (
                                        <>
                                        Search for your project area and click confirm area to relocate your project.
                                        </>
                                    ) : (
                                        <>
                                        Search for your project area and click confirm area to create a new project.
                                        </>
                                    )}
                                </div>
                            }
                            <div className="button-container">
                                <Button
                                    onClick={() => submitPolygon()}
                                    label="Confirm area"
                                    className="btn btn-success btn-lg"
                                />
                                { props.projectType !== 1 ?
                                    <Button
                                        onClick={() => resetPolygon()}
                                        label="Reset"
                                        className="btn btn-danger btn-lg"
                                    />
                                    : 
                                    <>
                                        {props.updateProjectLocation ? (
                                            <Button
                                                onClick={() => navigate('/project/detail', { state: {id: props.project.id} })}
                                                label="Cancel"
                                                className="btn btn-danger btn-lg"
                                            />
                                        ) : (
                                            <Button
                                                onClick={() => navigate('/projects')}
                                                label="Cancel"
                                                className="btn btn-danger btn-lg"
                                            />
                                        )}
                                    </>
                                }
                            </div>
                        </div>
                    ) : (
                        <>
                            {(props.polygonState.current_step === 4 ||
                                props.polygonState.current_step === 6 ||
                                props.polygonState.current_step === 5) && (
                                <Modal
                                    id="digitiseBoreholeModal"
                                    size="md"
                                    isOpen={modalVisibility}
                                    toggle={toggleModal}
                                    centered={true}
                                >
                                    <ModalBody className="text-center">
                                        <h3 className="mt-2">
                                            {props.polygonState.current_text}
                                        </h3>
                                        <hr className="mb-4" />
    
                                        {props.polygonState.current_text_add2 && (
                                            <div className="mt-2">
                                                <FontAwesomeIcon
                                                    icon={faClock}
                                                />{" "}
                                                {props.polygonState.current_text_add2}
                                            </div>
                                        )}

                                        {props.polygonState.current_text_add && (
                                            <div className="mt-2">
                                                <img
                                                    src="/assets/ai-icon.svg"
                                                    className="icon-ai"
                                                    alt=""
                                                />{" "}
                                                {props.polygonState.current_text_add}
                                            </div>
                                        )}

                                        {/* { props.polygonState.current_step !== 6 &&
                                            <div className="mt-2">
                                                <FontAwesomeIcon
                                                    icon={faLock}
                                                />{" "}
                                                Your uploaded data is kept private &
                                                confidential.
                                            </div>
                                        } */}


    
                                        {props.polygonState.current_step ===
                                        6 ? (
                                            <Button
                                                onClick={() => resetPolygon()}
                                                label="Continue"
                                                className="btn btn-success mt-5 mb-2"
                                            />
                                        ) : (
                                            <Button
                                                onClick={() => finalSubmit()}
                                                label="Okay"
                                                className="btn btn-success mt-5 mb-2"
                                            />
                                        )}
                                    </ModalBody>
                                </Modal>
                            )}
    
                            {props.polygonState.current_step === 1 && props.addProject === false && props.updateProjectLocation !== false && (
                                <div className="footer-bar-main">
                                    <div className="mt-2">
                                        {props.polygonState.current_text}{" "}
                                        <br />
                                        {props.polygonState.current_text_add}
                                    </div>
                                    <div>
                                        <Button
                                            onClick={() =>
                                                props.togglePolygonDrawn()
                                            }
                                            label="Cancel"
                                            className="btn btn-danger mt-2"
                                        />
                                    </div>
                                </div>
                            )}

                            {props.addProject && props.polygonState.current_step === 1 && (
                                <div className="footer-bar-main">
                                    <div className="mt-2">
                                        {props.polygonState.current_text}{" "}
                                        <br />
                                        {props.polygonState.current_text_add}
                                    </div>
                                    <div>
                                        <Button
                                            onClick={() =>
                                                navigate('/projects')
                                            }
                                            label="Cancel"
                                            className="btn btn-danger mt-2"
                                        />
                                    </div>
                                </div>
                            )}

                            {props.updateProjectLocation && props.polygonState.current_step === 1 && (
                                <div className="footer-bar-main">
                                    <div className="mt-2">
                                        {props.polygonState.current_text}{" "}
                                        <br />
                                        {props.polygonState.current_text_add}
                                    </div>
                                    <div>
                                        <Button
                                            onClick={() =>
                                                navigate('/project', { state: {id: props.project.id} })
                                            }
                                            label="Cancel"
                                            className="btn btn-danger mt-2"
                                        />
                                    </div>
                                </div>
                            )}      
                        </>
                    )}
                </>
    );
};

export default FooterBar;