import { useEffect, useMemo, useState } from 'react';
import Button from "../../shared/Button";
import BaseCard from './BaseCard';
import FileSelectorModal from './models/FileSelectorModal';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import httpClientPy from "../../../utils/httpClientPy"; 
import { toast } from 'react-toastify';
import FileUploaderModal from './models/FileUploaderModal';
 
const FileSelector = (props) => {

    const [filesSelected, setFilesSelected] = useState([]);
    const [filesUploaded, setFilesUploaded] = useState([]);
    const [toggleFileSelector, setToggleFileSelector] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const [areNodesRunning, setAreNodesRunning] = useState(false);
    const [toggleFileUploader, setToggleFileUploader] = useState(false);

    const handleRemove = (index) => {
        let newFilesSelected = filesSelected.filter((file, i) => i !== index);
        let newFilesUploaded = filesUploaded.filter((file, i) => file.name !== filesSelected[index].file_name);
        setFilesUploaded(newFilesUploaded);
        setFilesSelected(newFilesSelected);

        if (newFilesSelected.length === 0 || newFilesUploaded.length === 0) {
            props.setEnableRun(false);
        }
    }

    const getDocument = async (id) => {
        try {
            const response = await httpClientPy.get(`/geo/record?geo_record_id=${id}&admin=0&project_id=${props.project.id}`);
            return { id: id, file_name: response.data.original_file_name };
        } catch (err) {
            toast.error("Record not Found", { autoClose: 5000, position: toast.POSITION.TOP_RIGHT });
            return null;
        }
    }

    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                const filePromises = props.data.input.map(id => getDocument(id));
                const files = await Promise.all(filePromises);
                const validFiles = files.filter(file => file !== null);
                setFilesSelected(validFiles);
            } catch (error) {
                console.error("Error fetching documents:", error);
            }
        };
        
        if (props.data.input[0] != "") {
            fetchDocuments();
        }

        setIsInitialized(true);
    }, []);

    useEffect(() => {
        if (!isInitialized) {
            return;
        }
        
        let ids = filesSelected.filter(file => file?.id != null).map(file => file.id);
        props.setEnableRun(true);
        props.updateNodeData(props.id, { input: ids });
        
    }, [filesSelected]);

    useEffect(() => {
    
        props.setGlobalFileToUpload(filesUploaded);
        
    }, [filesUploaded]);

    return (
        <>
            <BaseCard {...props} areNodesRunning={areNodesRunning} setAreNodesRunning={setAreNodesRunning}>
                <div className='card-file-selector nowheel'>
                    {filesSelected.length < 1 ? (
                        <p className='ms-2 mt-2'>No files selected</p>
                    ) : (
                        <div className='m-1'>
                            {filesSelected.map((file, index) => (
                                <>
                                    <div key={index} className='d-flex justify-content-between mb-1 align-items-center'>
                                        <span className="text-truncate">{file.file_name}</span>
                                        {!areNodesRunning &&
                                            <Button onClick={() => handleRemove(index)}
                                                    label={<><FontAwesomeIcon icon={faTrash} size="sm" /></>} 
                                                    className='btn btn-sm btn-danger' />
                                        }
                                    </div>
                                </>
                            ))}
                        </div>
                    )}
                </div>
                {props.isDigitsationWf && 
                    <>
                        <Button onClick={() => setToggleFileUploader(!toggleFileUploader)} 
                                label='Upload Document(s)' 
                                className='w-100 btn btn-primary' 
                                disabled={areNodesRunning}
                        />
                        <div className="d-flex align-items-center">
                            <hr className="flex-grow-1 my-2"/>
                            <span className="px-2">or</span>
                            <hr className="flex-grow-1 my-2"/>
                        </div>
                    </>
                }
                <Button onClick={() => setToggleFileSelector(!toggleFileSelector)} 
                        label='Select Document(s)' 
                        className='w-100 btn btn-primary mt-2' 
                        disabled={areNodesRunning}
                />
            </BaseCard>

            <FileSelectorModal  toggleFileSelector = {toggleFileSelector} 
                                setToggleFileSelector = {setToggleFileSelector}
                                setFilesSelected = {setFilesSelected}
                                filesSelected = {filesSelected}
                                extraDetails = {props.extraDetails} 
                                project = {props.project}
                                isDigitsationWf = {props.isDigitsationWf}
            />

            <FileUploaderModal toggleFileUploader = {toggleFileUploader}
                                setToggleFileUploader = {setToggleFileUploader}
                                filesUploaded = {filesUploaded}
                                setFilesUploaded = {setFilesUploaded}
                                filesSelected = {filesSelected}
                                setFilesSelected = {setFilesSelected}

            />
        </>
    );
}

export default FileSelector;