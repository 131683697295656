import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Button from './Button';

function DeleteConfirmationModal({ itemId, onDeleteConfirm, type, disabled }) {
  const [modal, setModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const toggleModal = () => setModal(!modal);

  const handleDelete = () => {
    setDeleting(true);
    onDeleteConfirm(itemId, type);
    toggleModal();
  };

  return (
    <>
      {!deleting ? (
        <Button
            label={<FontAwesomeIcon icon={faTrash}/>}
            className="btn"
            onClick={toggleModal}
            disabled={deleting}
        /> 
      )
        :
      (
        <button className="btn disabled border-0" type="button" disabled>
            <Spinner size="sm" color="secondary" />
        </button>
      )
      }
        

      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Confirm Delete</ModalHeader>
        <ModalBody>
          Are you sure you want to delete this <b>{type === 'workflow' ? 'workflow' : type === 'workflow outputs' ? 'workflow output' : 'item'}?</b>
          {type == 'workflow' && <p className="text-danger">This action will remove all the data associated with this workflow. This action cannot be undone.</p>}
        </ModalBody>
        <ModalFooter>
            <Button
                label='Delete'
                className="btn btn-danger"
                onClick={handleDelete}
            />
            <Button
                label='Cancel'
                className="btn btn-secondary"
                onClick={toggleModal}
            />
        </ModalFooter>
      </Modal>
    </>
  );
}

export default DeleteConfirmationModal;
