import { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import httpClientPy from "../../../utils/httpClientPy";
import moment from "moment";

const FlowViewer = (props) => {

    const [showRunningFlows, setShowRunningFlows] = useState(false);
    const [showHistoricalFlows, setShowHistoricalFlows] = useState(false);
    const [flows, setFlows] = useState([]);

    const toggle = () => {
        props.setFlowSideToggler({history: false, running: false})
    };

    const handleJobClick = (jobId, workflowId) => {
        props.setAction('run', workflowId, jobId)
        props.setSelectedBtn("run")
        toggle();
    };

    const getActiveRuns = async (status) => {
        try {
            const response = await httpClientPy.get(`/workflow/runs?project_id=${props.project_id}&status=${status}`);
            setFlows(response.data.workflow_runs)
        } catch (err) {
            return [];
        }
    }

    function capitalizeFirstLetter(string) {
        if (!string) return ''; // Handle empty string case
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    useEffect(() => {
        setShowRunningFlows(props.flowSideToggler.running)
        setShowHistoricalFlows(props.flowSideToggler.history)
    }, [props.flowSideToggler])

    useEffect(() => {
        let interval = null;
        
        if (showRunningFlows) {
            interval = setInterval(() => {
                getActiveRuns('running')
            }, 1000);
        } else if (showHistoricalFlows) {
           getActiveRuns('completed')
        } else {
            clearInterval(interval);
        }

        // clean up the interval and also set flows to empty array
        return () => {
            clearInterval(interval);
            setFlows([]);
        }

    }, [showRunningFlows, showHistoricalFlows]);

    return (
        <Offcanvas show={showRunningFlows ? showRunningFlows : showHistoricalFlows} onHide={toggle} placement="end" scroll={true} className="job-side-card">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{showRunningFlows ? "Active Run(s)" : "Latest 10 Completed Run(s)"}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="align-items-center">
                {flows.length > 0 ? (
                    flows.map((flow) => (
                        <DropdownItem key={flow.id} onClick={() => handleJobClick(flow.id, flow.workflow_id)}>
                            <div className="job-card my-2">
                                <div className="job-info">
                                    <h3 className="job-name text-truncate">Job Name: <b>{flow.name}</b></h3>
                                    <p className="job-id">ID: <b>{flow.uuid}</b></p>
                                    <p className="job-started">Start Time: <b>{moment(flow.created_at).format('DD MMM YYYY hh:mm:ss A Z')}</b></p>
                                    <p className="time-elapsed">Time Elapsed: <b>{flow.elapsed_time}</b></p>
                                    <p className="job-status">Status: 
                                        <span className={`ms-1 badge rounded-pill documentBadge ${flow.status === 'running' ? 'blue' : flow.status === 'completed' ? 'green' : 'red'}`}>
                                            <b>{capitalizeFirstLetter(flow.status)}</b>
                                        </span>
                                    </p>
                                </div>
                                <div className="progress-container">
                                    <div className="progress-bar" style={{ width: `${flow.percentage}%` }}></div>
                                </div>
                                <p className="progress-text">{flow.percentage}% Complete</p>
                            </div>
                        </DropdownItem>
                    ))
                ) : (
                    <div className="container">
                        <div className="row">
                            <div className='d-flex justify-content-center flex-column align-items-center' style={{ height: '80vh' }}>
                                <h3><b>No {showRunningFlows ? "Active" : "Completed"} Run(s)</b></h3>
                                <div className="pt-2 text-center">There are 0 {showRunningFlows ? "active" : "completed"} runs for this project currently.</div>
                            </div>
                        </div>
                    </div>
                )}
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default FlowViewer;