import React, { useState } from 'react';
import {
  BaseEdge,
  EdgeLabelRenderer,
  getBezierPath,
  useReactFlow,
} from '@xyflow/react';
 
const ButtonEdge = (props) => {
  
  const [isHovered, setIsHovered] = useState(false);
  const { setEdges } = useReactFlow();
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX: props.sourceX,
    sourceY: props.sourceY,
    targetX: props.targetX,
    targetY: props.targetY,
    sourcePosition: 'right',
    targetPosition: 'left'
  });
 
  const onEdgeClick = () => {
    setEdges((edges) => edges.filter((edge) => edge.id !== props.id));
  };
  
  return (
    <>
      <BaseEdge path={edgePath} id={props.id} style={{ stroke: isHovered ? 'red' : '' }} />
      {!props.data.is_running && props.action !== 'run' &&
        <EdgeLabelRenderer>
          <div
            className="button-edge__label nodrag nopan"
            style={{
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            }}
          >
            <button className="button-edge__button" 
                    onClick={onEdgeClick}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
            >
              ×
            </button>
          </div>
        </EdgeLabelRenderer>
      }
    </>
  );
}

export default ButtonEdge;