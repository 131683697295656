import { useCallback, useState } from 'react';
import { Handle, Position } from '@xyflow/react';
import { Card } from 'react-bootstrap';
import BaseCard from './BaseCard';
 
const SearchBox = (props) => {

	const [searchTerm, setSearchTerm] = useState(props.data.input);
	const [areNodesRunning, setAreNodesRunning] = useState(false);

	const onChange = useCallback((evt) => {
		const value = evt.target.value;
		setSearchTerm(value);
		props.updateNodeData(props.id, { input: value });
	}, [props.id, props.updateNodeData]);

	return (
		<>	
			<BaseCard {...props} areNodesRunning={areNodesRunning} setAreNodesRunning={setAreNodesRunning}>
				<textarea
					id="text"
					name="searchValues"
					onChange={onChange}
					className="nodrag nowheel form-control"
					style={{ width: '100%' }}
					value={searchTerm ? searchTerm : ''}
					disabled={props.data.is_running || props.isDigitsationWf || areNodesRunning}
				></textarea>
			</BaseCard>
		</>
	);
}

export default SearchBox;